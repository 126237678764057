import React, { useState } from "react"

import { Link, graphql } from "gatsby"

import { addItemToOrder } from "api/db"

import Layout from "components/layout"
import SEO from "components/seo"
import ProductsInSubcategory from "components/Product/ProductsInSubcategory"

const ProductPage = ({ data }) => {
  let product = data.allGoogleSpreadsheetSheet1.edges[0].node
  let itemCode = product.itemCode
  let path = itemCode.replace(/\s+/g, "-").toLowerCase()

  const [quantity, setQuantity] = useState(1)
  const [showConfirmation, setShowConfirmation] = useState(false)

  function format(category) {
    if (category) {
      return category.replace(/-/g, " ")
    }
  }

  let price = (Math.round(product.price * 100) / 100).toFixed(2)

  return (
    <Layout>
      <SEO title={`${product.description} | Tick Tock Inc.`} />
      <div className="tabs is-toggle is-centered is-toggle-rounded">
        <ul>
          <li>
            <Link to="/">All</Link>
          </li>
          <li>
            <Link to="/categories/aluminum">Aluminum</Link>
          </li>
          <li>
            <Link to="/categories/cleaning">Cleaning</Link>
          </li>
          <li>
            <Link to="/categories/foam">Foam</Link>
          </li>
          <li>
            <Link to="/categories/health-and-beauty">Health & Beauty</Link>
          </li>
          <li>
            <Link to="/categories/aid">Medicine & Aid</Link>
          </li>
          <li>
            <Link to="/categories/mops-and-brooms">Mops & Brooms</Link>
          </li>
          <li>
            <Link to="/categories/plastic">Plastic</Link>
          </li>
          <li>
            <Link to="/categories/paper">Paper</Link>
          </li>
          <li>
            <Link to="/categories/stationary">Stationary</Link>
          </li>
          <li>
            <Link to="/categories/misc">Miscellaneous</Link>
          </li>
        </ul>
      </div>
      <section className="ticktockinc-container">
        <span className="product-page-category">
          <Link to={`/categories/${product.category}`}>
            {format(product.category)}
          </Link>{" "}
          /
        </span>{" "}
        <span className="product-page-subcategory">
          {format(product.subcategory)}
        </span>
        <h3 className="has-text-link has-text-weight-bold is-size-2">
          {product.itemCode}
        </h3>
        <div className="product-page-content">
          <figure className="product-page-image">
            <img
              alt={`Product Illustration of ${product.description}`}
              src={`/products/${path}.png`}
            />
          </figure>
          <div>
            <h1 className="has-text-centered has-text-weight-bold	is-size-3 product-page-description">
              {product.description}
              {product.stock === "yes" ? (
                <span className="tag is-success is-rounded is-stock">
                  {" "}
                  In Stock{" "}
                </span>
              ) : (
                <span className="tag is-danger is-rounded is-stock">
                  {" "}
                  Out of Stock{" "}
                </span>
              )}
            </h1>{" "}
            <p className="product-page-packing ">{product.packing}/case</p>
            <p className="product-page-pricing is-size-4">${price}</p>
            <p className="is-inline is-size-6">
              Case Price:{" "}
              <span className="product-page-case-pricing">
                ${(price * product.packing).toFixed(2)}
              </span>
            </p>
            <div className="control product-quantity">
              <p className="is-size-6">Enter quantity of cases:</p>
              <input
                className="input"
                type="number"
                pattern="\d*"
                min="1"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
              <span
                onClick={() => {
                  if (JSON.parse(localStorage.getItem("uid"))) {
                    addItemToOrder(
                      JSON.parse(localStorage.getItem("uid")),
                      path,
                      product.itemCode,
                      product.description,
                      product.price,
                      Math.floor(product.packing),
                      Math.floor(quantity)
                    )
                    setShowConfirmation(true)
                  } else {
                    alert(
                      "You must create an account or login to place an order."
                    )
                  }
                }}
              >
                Add to order
              </span>
            </div>
            <br />
            {showConfirmation && (
              <article className="message is-success is-product-success">
                <div className="message-header">
                  <p>Success</p>
                </div>
                <div className="message-body">
                  {quantity === 1 ? `${quantity} case` : `${quantity} cases`}{" "}
                  added to <Link to="/order">order</Link>.
                </div>
              </article>
            )}
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />

      <h3 className="has-text-centered is-size-3 has-text-weight-bold">
        {" "}
        Related Products{" "}
      </h3>
      <br />
      <br />

      <ProductsInSubcategory subcategory={product.subcategory} />
    </Layout>
  )
}

export const query = graphql`
  query ($itemCode: String!) {
    allGoogleSpreadsheetSheet1(filter: { itemCode: { eq: $itemCode } }) {
      edges {
        node {
          category
          subcategory
          itemCode
          packing
          price
          description
          stock
        }
      }
    }
  }
`

export default ProductPage
