import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { retrieveOrders } from "api/db"
import ProductCell from "./ProductCell"

const ProductsInSubcategory = ({ subcategory }) => {
  const [orderInProgress, setOrderInProgress] = useState(false)
  const [activeOrder, setActiveOrder] = useState([])

  const data = useStaticQuery(
    graphql`
      query {
        allGoogleSpreadsheetSheet1(
          filter: { deleted: { ne: "yes" }, stock: { eq: "yes" } }
        ) {
          nodes {
            category
            subcategory
            itemCode
            packing
            price
            description
            stock
          }
        }
      }
    `
  )

  let products = data.allGoogleSpreadsheetSheet1.nodes
  products = products.filter(product => product.subcategory === subcategory)

  let uid
  // if logged it retreieve to see if there's an active order!!
  if (typeof window !== "undefined") {
    uid = JSON.parse(localStorage.getItem("uid"))
  } else {
    uid = "null"
  }

  if (uid != "null" && !orderInProgress) {
    retrieveOrders(uid).then(snapshot => {
      let order = snapshot.val()[Object.keys(snapshot.val())]
      if (order.status === "in-progress") {
        let items = order
        delete items.dateUpdated
        delete items.orderNumber
        delete items.status
        delete items.uid
        setOrderInProgress(true)
        setActiveOrder(items)
      } else {
        // no order in progress
      }
    })
  }

  return (
    <div className="columns is-multiline">
      {products.map(product => (
        <ProductCell
          product={product}
          uid={uid}
          orderInProgress={orderInProgress}
          activeOrder={activeOrder}
        />
      ))}
    </div>
  )
}

export default ProductsInSubcategory
